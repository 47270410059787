import { Parcels as Base } from 'core/api/Parcels'
import { api, responseErrorCheck, ShippingParcel } from 'stylewhere/api'

export class Parcels extends Base {
  static async forcePin({ userPin, ...postData }: { parcelCodes: string[]; operationId: string; userPin: string }) {
    const res = await api.post<ShippingParcel>(`api/v1/operations/simpleParcelList/executeAction`, {
      ...postData,
      actionCode: 'FORCE_WITH_CHIEF_PIN',
      attributes: { userPin },
    })
    return responseErrorCheck(res)
  }
}
